@import "~react-image-gallery/styles/css/image-gallery.css";
@import "react-alice-carousel/lib/alice-carousel.css";
/* 
.yclass{
    width: 25rem;
    margin-left: 30%;
}
.tclass{
    width: 25rem;
    height: 30rem;
    margin-left: 30%;
}

@media only screen and (max-width: 700px) {
    .yclass{
        width: 25rem;
        height: 15rem;
        margin-left: 20%;
    }
    .tclass{
        width: 20rem;
        height: 12rem;
        margin-left: 22%;
    }
  }

@media only screen and (max-width: 400px) {
    .yclass{
        width: 25rem;
        height: 15rem;
        margin-left: 0;
    }
    .tclass{
        width: 20rem;
        height: 12rem;
        margin-left: 2rem;
    }
  } */

@media only screen and (max-width: 350px) {
    .yclass{
        width: 20rem;
        height: 15rem;
    }
    .tclass{
        width: 16rem;
        height: 12rem;
       
    }
  }